import "./App.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faHome } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
import trackVisitor from "./utilities/tracker";

import GameResult from "./js/GameResult";
import AdvertisementComponent from "./utilities/advertismentComponent";
import { Helmet } from "react-helmet";
// const momenttz = require("moment-timezone");
function App() {
  useEffect(() => {
    trackVisitor();
  }, []);
  const [data, setData] = useState([]); // State to store data fetched from backend
  const [datagame, setDataFor] = useState([]); // State to store processed data for display
  const [currentTime, setCurrentTime] = useState(moment().format("HH:mm:ss"));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format("HH:mm:ss"));
    }, 1000);
    
    return () => clearInterval(interval);
  }, []);

  // const location = useLocation();
  // const isDisclaimer = location.pathname.includes("/disclaimer");
  // const isAbout = location.pathname.includes("/about-us");
  // const isPrivacy = location.pathname.includes("/privacy-policy");
  const todayDate = moment(new Date()).tz("Asia/Kolkata").format("YYYY-MM-DD");


  

  var currentDate = moment(new Date()).tz("Asia/Kolkata").format("YYYY-MM-DD");
  const currentTimes = moment().format("HH:mm");
  var prevDate = moment(new Date())
    .subtract(1, "days")
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD");

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // Sort data based on open_time
        const sortedData = json.sort((a, b) => {
          const timeA = moment(a.open_time, "HH:mm");
          const timeB = moment(b.open_time, "HH:mm");
          return timeA.diff(timeB);
        });

        // Set sorted data into state
        setData(sortedData);
      })
      .catch((error) => console.error(error));
  }, [currentDate, prevDate]);

  useEffect(() => {
    if (data?.length > 0) {
      const currentMoment = moment(currentTime, "HH:mm");
  
      const processedData = data.map((item) => {
        const itemTime = moment(item.open_time, "HH:mm");
        const resultAvailable = item?.curr_date?.result ? true : false;
  
        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : "wait",
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime,
        };
      });
  
      const sortedProcessedData = processedData.sort((a, b) => 
        a.itemTime.diff(b.itemTime)
      );
  
      const availableResults = sortedProcessedData.filter((item) => item.isAvailable);
      const upcomingRecords = sortedProcessedData.filter((item) => !item.isAvailable);
  
      let recordsToDisplay = [];
  
      if (availableResults.length > 0) {
        recordsToDisplay = [...availableResults];
  
        const lastAvailableIndex = sortedProcessedData.indexOf(
          availableResults[availableResults.length - 1]
        );
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        recordsToDisplay = [...upcomingRecords.slice(0, 3)];
      }
  
      if (recordsToDisplay.length > 3) {
        recordsToDisplay = recordsToDisplay.slice(-3).reverse();
      }
  
      // Move "wait" items to the top
      recordsToDisplay.sort((a, b) => (a.result === "wait" ? -1 : 1));
  
      setDataFor(recordsToDisplay);
    }
  }, [data, currentTime]);
  // pagescroll
  function pageScroll() {
    const tableSection = document.getElementById("monthTable");
    if (tableSection) {
      tableSection.scrollIntoView({ behavior: "smooth" });
    }
  }

 
  
  return (
    <div className="">
      {/* seo setup start */}
      <Helmet>
        <title></title>
        <meta name="description" content="sattakingno,satta king no" />
        <meta
          name="Keywords"
          content="sattakingno,satta king no, sattakingreal, satta king real, sattaking real, Satta King, Satta King live result, Satta king online result, Satta king online, Satta king result today, Gali result, Desawar result, Faridabad result, Gaziyabad result, Satta matka king, Satta Bazar, Black satta king, Satta king 2017, satta king 2018, Gali Leak Number, Gali Single Jodi, Black Satta Result, Desawar Single Jodi, Satta king up, Satta king desawar, Satta king gali, Satta king 2019 chart, Satta baba king, Satta king chart, Gali live result, Disawar live result, Satta Number, Matka Number, Satta.com, Satta Game, Gali Number, Delhi Satta king,"
        />
        <link rel="canonical" href="https://satta-no.co.in/" />
      </Helmet>
      {/* seo setup end */}
      <div className="col-md-12 menu text-center">
        <div className="row">
          <div className="col-md-1 col-sm-12 nav-link">
            <a className="text-white" href="/">
              HOME{" "}
            </a>
          </div>
          <div className="col-md-3 col-sm-12 nav-link">
            <a className="text-white" href="/">
            🎯 SATTA KING 786 🎯
            </a>
          </div>
          <div className="col-md-3 col-sm-12 nav-link">
            <a className="text-white" onClick={pageScroll}>
              SATTA CHART
            </a>
          </div>
          <div className="col-md-3 col-sm-12 nav-link">
            <a className="text-white" href="/">
            👑SATTA MATKA👑
            </a>
          </div>
          <div className="col-md-2 col-sm-12 nav-link">
            <a className="text-white" href="http://satta-leak.co/">
              SATTA LEAK
            </a>
          </div>
        </div>
        <div className="text-center highlight p-1 king">
          <h1 className="fw-bold">SATTA KING</h1>
        </div>
      </div>

      <div className="text-center mt-4">
        <div className="text-center p-1  fw-bold text-white themecolor">
          <p> 🚨SATTA KING BEST SITE SATTA RESULT🚨</p>
          <h2>WWW.SATTA-NO.CO.IN</h2>
        </div>

        <div className="text-center backcolor">
          <p className="text-danger fw-bold fs-3 mb-0">{todayDate}</p>
          <p className="text-primary fw-bold fs-4 mb-0">Current Time: {currentTime}</p>

          <p className="text-dark fw-bold fs-5 mb-0">
            Satta King Live Result Today
          </p>
          {datagame?.map((todayData, index) => (
            <div key={index} className="game">
              <h3 className="fw-bold mb-0 fs-2 livegame">
                {todayData?.gameName}
              </h3>
              <h5 className=" blinking-text fw-bold mb-0 fs-2">
                {todayData?.result || ""}
              </h5>
            </div>
          ))}
        </div>
        <AdvertisementComponent type="odd" />
        <GameResult dayGameData={data} />
      </div>
    </div>
  );
}

export default App;
