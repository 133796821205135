import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const AdvertisementComponent = ({ type }) => {
  const [oddAdd, setOddAdd] = useState([]);
  const [evenAdd, setEvenAdd] = useState([]);
  const [randomAdd, setRandomAdd] = useState([]);

  useEffect(() => {
    const dummyData = [
      {
        name: "ANKIT SHARMA",
        description: "⚡HOGA DHAMAKA SINGAL SHOT KE SATH⚡✅FARIDABAD | ✅GAZIYABAD | ✅GALI | ✅DS DIRECT COMPANY👑 SE LEAK JODI JO BHAI APNA LOSS COVER KARNA CHAHTE HO💵💵🙂 ,GAME SINGAL JODI MAI HE MILEGA ,GAME KISI KA BAAP ☝ NAHI KAAT SAKTA ,☝APNI BOOKING KARANE K LIYE ABHI WHATSAPP YA CALL KARE !☎️SATTA KING NOADDFARIDABAD LEAK   ",
        mobile: "	9999999999",
        post: "	MANAGER",
      },
      {
        name: "SATTA KING CHART",
        description: "✅ FARIDABAD | GAZIABAD | GALI | DESAWAR ✔♠️ MAI GAME MILEGA SINGLE JODI ✅⚡ 1001% PASS GUARANTEE ❤️⭕ JISKA BHI LOSS HO YA KARZA HO ☝✔ SARA COVER KAR LO ✅MAHINE KA LAKHO 💵💵PROFIT KRO JIS BHAI KO DIRECT COMPANY KE SATH ✅✔ MIL KAR KAAM KRNA HAI ✅⚡ ABHI WHATSAPP KAREN ☎️",
        mobile: "9999999991",
        post: "ADD",
      },
      {
        name: "आकाश ठाकुर खाईवाल भैया जी",
        description: "✅ 101% GURANTEE PAYMENT KI ❤️ पेमेंट रेट ✅♣️ जोड़ी रेट:✅ 10 के 960 ☝✔ हर्फ़ रेट ✅ 100 के 960 PM ☝⚡ टाइम टेबल ⚡✔ लखनऊ गोल्ड ➪ 12:05 PM ☝♣️ दिल्ली बाजार ➪ 03:00 PM ✅श्री गणेश ➪ 04:30 PM ☝❤️ फरिदाबाद ➪ 05:55 PM ✅✔ गाजियाबाद ➪ 08:50 PM ☝♠️ गली ➪ 11:00 PM ✅✔ दिसावर ➪ 04:40 AM ✅✅ पेमेंट रेट ✔ जोड़ी रेट 10 के 960 ✅❤️ 95 हर्फ़ रेट 100 के 960 PM ☝❗NOTE✅ सुपर फास्ट पेमेंट का वादा ✔⚡ मेरे पास गेम पास होने के 10 मिनट बाद आपकी पेमेंट तुरंत दी जाएगी ☎️❤️ अंदर अंदर होगा ✅",
        mobile: "9668665275",
        post: "आकाश ठाकुर खाईवाल भैया जी",
      },
    ];

    setOddAdd([dummyData[0]]);
    setEvenAdd([dummyData[1]]);
    setRandomAdd([dummyData[2]]);
  }, []);

  const generateWhatsAppLink = (mobile, name) => {
    const message = `Hello ${name}, I am interested in your advertisement.`;
    return `https://api.whatsapp.com/send?phone=${mobile}&text=${encodeURIComponent(message)}`;
  };

  const generateCallLink = (mobile) => {
    return `tel:${mobile}`;
  };

  const renderAds = (ads) => (
    <div className="row">
      {ads.length > 0 ? (
        ads.map((ad, index) => (
          <div key={index} className="col-md-12 ad-section">
            <div className="col-lg-12">
              <h3>Name: {ad.name}</h3>
              <p>{ad.description}</p>
              <h4>Mobile: {ad.mobile}</h4>
              <h4>Post: {ad.post}</h4>
              <div className="col-12">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <a href={generateWhatsAppLink(ad.mobile, ad.name)} target="_blank" className="form-control m-2 btn ad-btn col-md-6 col-sm-12">
                      <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp Now
                    </a>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <a href={generateCallLink(ad.mobile)} className="form-control m-2 btn ad-btn col-md-6 col-sm-12">
                      <FontAwesomeIcon icon={faPhone} /> Call Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );

  return <div className="advertisementSection">{type === "odd" && renderAds(oddAdd)}{type === "even" && renderAds(evenAdd)}{type === "random" && renderAds(randomAdd)}</div>;
};

export default AdvertisementComponent;
